<template>
  <div class="warehouse-list-base-page">
    <div class="feature-form-container">
      <el-form
        :inline="true"
        :model="dataForm"
        ref="dataForm"
        class="bysearchForm"
      >
        <div class="searchLine">
          <el-form-item class="typeInput">
            <el-input
              v-model="dataForm.workId"
              placeholder="工号"
              size="mini"
              clearable
            />
          </el-form-item>
          <el-form-item class="typeInput">
            <el-input
              v-model="dataForm.name"
              placeholder="姓名"
              size="mini"
              clearable
            />
          </el-form-item>
          <el-form-item class="typeInput">
            <el-input
              v-model="dataForm.phone"
              placeholder="手机号码"
              size="mini"
              clearable
            />
          </el-form-item>
          <el-form-item class="typeInput">
            <el-input
              v-model="dataForm.levelName"
              placeholder="员工级别"
              size="mini"
              clearable
            />
          </el-form-item>
          <el-form-item class="typeInput">
            <el-select
              v-model="dataForm.subsidyType"
              placeholder="补助类型"
              size="mini"
              clearable
            >
              <el-option
                v-for="item in subsidyTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button @click="getSerialList(null, 1, 1)" size="mini"
            >查询</el-button
          >
          <el-button
            v-if="isAuth('tc:employees:save')"
            type="primary"
            size="mini"
            @click="$dialog('addOrUpdate')"
            >新增员工信息</el-button
          >
          <el-button
            v-if="isAuth('tc:employees:update')"
            type="info"
            size="mini"
            :disabled="!(serialListSelections.length === 1)"
            @click="$dialog('addOrUpdate', serialListSelections[0].id)"
            >修改员工信息</el-button
          >
          <el-button
            v-if="isAuth('tc:employees:info')"
            type="success"
            size="mini"
            :disabled="!(serialListSelections.length === 1)"
            @click="$dialog('addOrUpdate', serialListSelections[0].id, true)"
            >查看员工信息</el-button
          >
          <el-button
            v-if="isAuth('tc:employees:delete')"
            type="danger"
            size="mini"
            :disabled="serialListSelections.length <= 0"
            @click="deleteHandle()"
            >删除员工信息</el-button
          >
          <el-button
            type="primary"
            v-if="isAuth('tc:employees:importEmployees')"
            @click="$dialog('employeesImport')"
            >导入员工信息</el-button
          >
          <el-button
            type="primary"
            v-if="isAuth('tc:employees:employeesRecharge')"
            @click="$dialog('employeesRecharge',serialListSelections)"
            >手动分配补助</el-button
          >
          <el-button
            type="primary"
            v-if="isAuth('tc:employees:userRecharge')"
            @click="$dialog('userRecharge',serialListSelections)"
            >余额充值</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="goods-wrap">
      <div class="goods-detail-container leftSideWay">
        <div class="detail-container">
          <div class="table-container">
            <el-table
              :data="dataList"
              ref="dataList"
              border
              :row-class-name="tableRowClassName"
              @selection-change="$selectionChange($event, 'dataList')"
              @row-click="
                $clickRow(
                  $event,
                  'dataList',
                  getSerialList($event, null, 1),
                  colorChange($event),
                )
              "
              @select="$select(arguments, 'dataList')"
              @select-all="$clearSelection('dataList')"
            >
              <table-tree-column
                prop="name"
                header-align="center"
                tree-key="id"
                label="公司及部门"
              />
              <el-table-column
                prop="type"
                header-align="center"
                align="center"
                label="属性"
              >
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.type === 0" size="small">公司</el-tag>
                  <el-tag
                    v-else-if="scope.row.type === 1"
                    size="small"
                    type="success"
                    >部门</el-tag
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="divider-border" />
      <div class="goods-list-container rightSideWay">
        <div>补助额度合计：{{ total }}元</div>
        <div class="table-container">
          <el-table
            :data="serialList"
            ref="serialList"
            border
            stripe
            v-loading="serialListLoading"
            @row-click="$clickRow($event, 'serialList')"
            @selection-change="$selectionChange($event, 'serialList')"
            style="width: 100%"
          >
            <el-table-column
              type="selection"
              header-align="center"
              align="center"
              width="50"
            />
            <el-table-column
              prop="workId"
              header-align="center"
              align="center"
              label="工号"
            />
            <el-table-column
              prop="uuid"
              header-align="center"
              align="center"
              label="用户id"
            />
            <el-table-column
              prop="levelName"
              header-align="center"
              align="center"
              label="员工级别"
            />
            <el-table-column
              prop="amount"
              header-align="center"
              align="center"
              label="补助额度"
            />
            <el-table-column
              prop="name"
              header-align="center"
              align="center"
              label="姓名"
            />
            <el-table-column
              prop="gender"
              header-align="center"
              align="center"
              label="性别"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.gender === 1" type="success" size="mini"
                  >男</el-tag
                >
                <el-tag
                  v-else-if="scope.row.gender === 2"
                  type="danger"
                  size="mini"
                  >女</el-tag
                >
                <el-tag v-else type="info" size="mini">未知</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="phone"
              header-align="center"
              align="center"
              width="160"
              label="手机号码"
            />
            <el-table-column
              prop="subsidyName"
              header-align="center"
              align="center"
              label="补助形式"
            >
            </el-table-column>
            <el-table-column
              prop="departmentName"
              header-align="center"
              align="center"
              label="部门"
            >
            </el-table-column>
            <el-table-column
              prop="isBinding"
              header-align="center"
              align="center"
              label="是否绑定小程序"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.isBinding" type="success" size="mini"
                  >已绑定</el-tag
                >
                <el-tag v-if="!scope.row.isBinding" type="warning" size="mini"
                  >未绑定</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              header-align="center"
              align="center"
              label="状态"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status" type="success" size="mini"
                  >在职</el-tag
                >
                <el-tag v-if="!scope.row.status" type="warning" size="mini"
                  >离职</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="isTransferBalance"
              header-align="center"
              align="center"
              label="是否可转补助"
              v-if="isViewAll"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.isTransferBalance"
                  type="success"
                  size="mini"
                  >是</el-tag
                >
                <el-tag
                  v-if="!scope.row.isTransferBalance"
                  type="warning"
                  size="mini"
                  >否</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column
              prop="isBindingPeriod"
              header-align="center"
              align="center"
              label="是否绑定时段"
              v-if="isViewAll"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.isBindingPeriod"
                  type="success"
                  size="mini"
                  >是</el-tag
                >
                <el-tag
                  v-if="!scope.row.isBindingPeriod"
                  type="warning"
                  size="mini"
                  >否</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            @size-change="$sizeChange($event, 'serialList')"
            @current-change="$currentChange($event, 'serialList')"
            :current-page="serialListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="serialListSize"
            :total="serialListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getSerialList(null, null, 1)"
    />
    <employees-import
      v-if="employeesImportVisible"
      ref="employeesImport"
      @success="getSerialList(null, null, 1)"
    />
    <employees-recharge
      v-if="employeesRechargeVisible"
      ref="employeesRecharge"
      @success="getSerialList(null, null, 1)"
    />
    <user-recharge
      v-if="userRechargeVisible"
      ref="userRecharge"
      @success="getSerialList(null, null, 1)"
    />
  </div>
</template>

<script>
import AddOrUpdate from './employees-add-or-update';
import EmployeesImport from './employees-import';
import EmployeesRecharge from './employees-recharge';
import UserRecharge from './user-recharge';
import { threeListMixin, normal } from '@/mixins';
import TableTreeColumn from '@/components/table-tree-column';

export default {
  mixins: [threeListMixin, normal],
  data() {
    return {
      dataForm: {
        name: '',
        workId: '',
        phone: '',
        levelName: '',
        subsidyType: '',
      },
      total: 0,
      chooseIndex: 0,
      dataList: [],
      serialList: [],
      subsidyTypeList: [
        { id: 1, name: '按天补助' },
        { id: 2, name: '按月补助' },
      ],
      isViewAll: false,
      companyId: '',
      departmentId: '',
      type: '',
      addOrUpdateVisible: false,
      employeesImportVisible: false,
      employeesRechargeVisible: false,
      userRechargeVisible: false,
    };
  },

  //注册需要引入的组件，即一个vue页面，这里指employees-add-or-update页面，
  components: {
    TableTreeColumn,
    //要注册的文件首字母大写
    AddOrUpdate,
    EmployeesImport,
    EmployeesRecharge,
    UserRecharge,
  },

  activated() {
    this.getDataList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      if (row.id === this.chooseIndex) {
        return 'success-row';
      } else {
        return '';
      }
    },
    colorChange(row) {
      this.chooseIndex = row.id;
    },
    //获取数据列表
    getDataList() {
      this.$http({
        url: '/tc/employees/listTree',
        method: 'get',
      }).then(({ data }) => {
        this.dataList = this.treeDataTranslate(
          data.companyAndDepartment,
          'id',
          'parentId',
          'childrens',
        );
        this.getSerialList(data.companyAndDepartment[0], null, 1);
        this.companyId = data.companyAndDepartment[0].companyId;
      });
    },

    getSerialList(row, isClear, page) {
      if (isClear !== 1) {
        this.dataForm.name = null;
        this.dataForm.workId = null;
        this.dataForm.levelName = null;
        this.dataForm.phone = null;
      }
      this.companyId = row && row.companyId ? row.companyId : this.companyId;
      this.type = row && row.type ? row.type : this.type;
      this.departmentId = row
        ? row.departmentId
        : this.type === 1
        ? this.departmentId
        : null;
      this.serialListIndex = page ? page : this.serialListIndex;
      this.$api({
        url: '/tc/employees/list',
        params: {
          page: this.serialListIndex,
          limit: this.serialListSize,
          companyId: this.companyId,
          departmentId: this.departmentId,
          ...this.dataForm,
        },
        after: (data) => {
          this.isViewAll = data.isViewAll;
          this.total = data.total;
          if (data && data.code === 0) {
            if (data && data.page.records?.length) {
              this.serialList = data.page.records;
              this.serialListCount = data.page.total;
            } else {
              this.serialList = [];
              this.serialListCount = 0;
            }
          } else {
            this.serialList = [];
            this.serialListCount = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.serialListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些数据进行删除操作?`,
        url: '/tc/employees/delete',
        data: ids,
        after: () => {
          this.getSerialList(null, null, 1);
        },
      });
    },
  },
};
</script>

<style>
/* element滚动条组件 隐藏水平滚动条 */
.sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
/*.is-horizontal {*/
/*  display: none;*/
/*}*/
/*.el-row {*/
/*  margin-bottom: 20px;*/
/*&:last-child {*/
/*   margin-bottom: 0;*/
/* }*/
/*}*/
/*.el-col {*/
/*  border-radius: 4px;*/
/*}*/
/*.bg-purple-dark {*/
/*  background: #99a9bf;*/
/*}*/
.bg-purple {
  background: #d3dce6;
}
/*.bg-purple-light {*/
/*  background: #e5e9f2;*/
/*}*/
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/*.row-bg {*/
/*  padding: 10px 0;*/
/*  background-color: #f9fafc;*/
/*}*/
</style>
