<template>
  <el-dialog
    :title="
      !dataForm.id ? '新增员工' : !disabled ? '修改员工信息' : '查看员工信息'
    "
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="公司及部门" prop="companyAndDepartment">
          <el-cascader
            v-model="dataForm.companyAndDepartment"
            @change="changeCompany()"
            :disabled="disabled"
            :options="options"
            :props="{ expandTrigger: 'hover' }"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="员工工号" prop="workId">
          <el-input
            v-model="dataForm.workId"
            :disabled="disabled"
            placeholder="员工工号"
          />
        </el-form-item>
        <el-form-item label="员工级别" prop="levelId">
          <el-select
            v-model="dataForm.levelId"
            filterable
            @change="changeLevel()"
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in levelList"
              :key="index"
              :label="item.nameAndType"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="员工姓名"
          />
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="dataForm.gender" :disabled="disabled">
            <el-option
              v-for="item in genderOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            v-model="dataForm.phone"
            :disabled="disabled"
            placeholder="手机号码"
          />
        </el-form-item>
        <el-form-item label="余额" prop="balance" v-if="disabled">
          <el-input
            v-model="dataForm.balance"
            :disabled="disabled"
            placeholder="余额"
            v-if="disabled"
          />
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question"> </i>

            <div style="width: 200px" slot="content">该员工的就职状态</div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.status"
            :disabled="disabled"
            placeholder="在职"
            >在职</el-checkbox
          >
        </el-form-item>

        <el-form-item
          label="是否可转补助"
          prop="isTransferBalance"
          v-if="isDay"
        >
          <el-radio-group
            v-model="dataForm.isTransferBalance"
            :disabled="disabled"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否绑定时段" prop="isBindingPeriod" v-if="isDay">
          <el-radio-group
            v-model="dataForm.isBindingPeriod"
            :disabled="disabled"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
import {
  getCompanyList,
  getDepartmentList,
  getSubsidyList,
} from '@/utils/options.js';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      disabled: false,
      dataForm: {
        id: 0,
        userId: '',
        companyId: '',
        departmentId: '',
        companyAndDepartment: '',
        workId: '',
        levelId: '',
        levelName: '',
        name: '',
        gender: '',
        phone: '',
        subsidyId: '',
        subsidyType: '',
        subsidyName: '',
        balance: '',
        status: true,
        isTransferBalance: true,
        isBindingPeriod: false,
      },
      isDay: false,
      options: [],
      levelList: [],
      subsidyList: [],
      companyList: [],
      genderOptions: [
        { id: 1, name: '男' },
        { id: 2, name: '女' },
        { id: 0, name: '其他' },
      ],
      dataRule: {
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        levelId: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        subsidyId: [
          { required: true, message: '补助形式不能为空', trigger: 'blur' },
        ],
        phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
        companyAndDepartment: [
          { required: true, message: '公司及部门不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getCompanyList();
    this.getDepartmentList();
  },
  methods: {
    init(id, disabled) {
      this.$init({
        before: () => {
          this.getParentCompanyId();
          this.getSubsidyList();
          this.getLevelList(this.dataForm.companyId);
          this.disabled = disabled;
        },
        url: id ? `/tc/employees/info/${id}` : null,
        after: (data) => {
          if (data && data.code === 0) {
            this.dataForm = { ...data.employees };
            this.dataForm.companyAndDepartment = [
              this.dataForm.companyId,
              this.dataForm.departmentId,
            ];
            this.changeLevel();
          }
        },
      });
    },
    refreshLevelList(id) {
      this.levelList = null;
      this.getRefreshLevelList(id);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (
            this.dataForm.companyAndDepartment !== null &&
            this.dataForm.companyAndDepartment.length > 0
          ) {
            this.dataForm.companyId = this.dataForm.companyAndDepartment[0];
            this.dataForm.departmentId = this.dataForm.companyAndDepartment[1];
          }
          this.$http({
            url: `/tc/employees/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              id: this.dataForm.id || undefined,
              // userId: this.dataForm.userId,
              ...this.dataForm,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('success');
                },
              });
            }
          });
        }
      });
    },
    changeLevel() {
      let subsidyType = this.levelList.filter(
        (i) => i.id === this.dataForm.levelId,
      )[0].subsidyType;
      subsidyType === 1 ? (this.isDay = true) : (this.isDay = false);
    },
    getDepartmentList() {
      getDepartmentList().then(({ data }) => {
        if (data && data.code === 0) {
          this.options = data.option.options;
        }
      });
    },
    getLevelList(id) {
      this.$http({
        url: `/tc/companyLevel/combo/${id}`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.getParentCompanyId();
          this.levelList = data.list;
        }
      });
    },
    getRefreshLevelList(id) {
      this.$http({
        url: `/tc/companyLevel/combo/${id}`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.levelList = data.list;
        }
      });
    },
    //获取补助方式列表
    getSubsidyList() {
      getSubsidyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.subsidyList = data.list;
          this.getParentCompanyId();
        }
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },

    getParentCompanyId() {
      this.dataForm.companyId = this.$parent.companyId;
    },
    changeCompany() {
      this.dataForm.companyId = this.dataForm.companyAndDepartment[0];
      this.getLevelList(this.dataForm.companyId);
    },
  },
};
</script>
